/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { client } from './apollo/client'

const addScript = url => {
    const script = document.createElement("script")
    script.src = url
    script.async = true
    document.body.appendChild(script)
}
  
export const onClientEntry = () => {
    window.onload = () => {
        addScript(`https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_MAPS_API_KEY}&callback=initAutocomplete&libraries=places&v=weekly`)
    }
}

export const wrapRootElement = ({ element }) => (
    <ApolloProvider client={client}>{ element }</ApolloProvider>
);